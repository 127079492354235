exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beta-home-js": () => import("./../../../src/pages/beta-home.js" /* webpackChunkName: "component---src-pages-beta-home-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-templates-category-category-children-jsx": () => import("./../../../src/templates/category/CategoryChildren.jsx" /* webpackChunkName: "component---src-templates-category-category-children-jsx" */),
  "component---src-templates-category-service-landing-jsx": () => import("./../../../src/templates/category/ServiceLanding.jsx" /* webpackChunkName: "component---src-templates-category-service-landing-jsx" */),
  "component---src-templates-pages-page-jsx": () => import("./../../../src/templates/pages/Page.jsx" /* webpackChunkName: "component---src-templates-pages-page-jsx" */),
  "component---src-templates-posts-post-jsx": () => import("./../../../src/templates/posts/Post.jsx" /* webpackChunkName: "component---src-templates-posts-post-jsx" */),
  "component---src-templates-posts-service-landing-post-jsx": () => import("./../../../src/templates/posts/ServiceLandingPost.jsx" /* webpackChunkName: "component---src-templates-posts-service-landing-post-jsx" */),
  "component---src-templates-publications-cover-jsx": () => import("./../../../src/templates/publications/Cover.jsx" /* webpackChunkName: "component---src-templates-publications-cover-jsx" */),
  "component---src-templates-publications-publication-jsx": () => import("./../../../src/templates/publications/Publication.jsx" /* webpackChunkName: "component---src-templates-publications-publication-jsx" */)
}

