/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/components/bootstrap.min.css";
import "./src/components/static/fonts/fontawsome.css"
import "./src/components/static/fonts/fonts.css"


export const onClientEntry= () => {
if (typeof __canGDPR == 'function') { 
    console.log('onClientEntry')
    window.__canGDPR(function() {
      console.log('onClientEntry - CANS')
      var createScript = function(url, callback) {
        var script = document.createElement('script');
        script.src = url;
        if (callback) {
          script.onload = callback; 
        }document.getElementsByTagName('script')[0].appendChild(script);
      };
      createScript('https://www.googletagmanager.com/gtag/js?id=GTM-W8FQ6Q');
      window.dataLayer = window.dataLayer || [];
      function gtag(){
        window.dataLayer.push(arguments);
      }gtag('js', new Date());
      gtag('config', 'GTM-W8FQ6Q');
    });
};
}

export const onRouteUpdate = () => {
    console.log('onRouteUpdate');
if (typeof __canGDPR == 'function') { 
    window.__canGDPR(function() {
      console.log('onRouteUpdate - CANS')
      var createScript = function(url, callback) {
        var script = document.createElement('script');
        script.src = url;
        if (callback) {
          script.onload = callback; 
        }document.getElementsByTagName('script')[0].appendChild(script);
      };
      createScript('https://www.googletagmanager.com/gtag/js?id=GTM-W8FQ6Q');
      window.dataLayer = window.dataLayer || [];
      function gtag(){
        window.dataLayer.push(arguments);
      }gtag('js', new Date());
      gtag('config', 'GTM-W8FQ6Q');
    });
};
}
